import React from "react";
import {Helmet} from "react-helmet";

export default function Policy(){
    return (
        <>
        <br /><br />

        <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy policy</title>
                <meta name="description" content="Privacy policy of the Arfusoft website." />              
                <meta name="keywords" content="policy,privacy,privacy policy"/>
                <meta property="og:title" content="Privacy policy" />        
                <meta property="og:description" content="Privacy policy of the Arfusoft website." />        
                <meta property="og:site_name" content="Privacy policy" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
          </Helmet>


        
          <section id="policy" className="team-area pt-120">
    <div className="container">
        <div className="justify-center row">
            <div className="w-full lg:w-2/3">
                <div className="pb-8 text-center section-title">
                    <div className="m-auto line"></div>
                    <h1 className="title text-purple-500"><span></span> Privacy policy</h1>
                </div> {/* section title */}
            </div>
        </div> {/* row */}
        <div className="justify-center row">
            <div className="w-full">
                <aside>
                <details open>
                    <summary className="text-sky-700"> Click to expand/collapse
                        
                    </summary>
                    <h5>
                        This page informs visitors about our policies regarding the collection, use, and disclosure of Personal information if anyone decides to use our Service. 
                        If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. 
                        The Personal information we collect is used to provide and improve the Service. 
                        We will not use or share your information with anyone except as described in this Privacy Policy. 
                        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at our applications unless otherwise defined in this Privacy Policy.<br/><br/>
                        ✤ <strong>Information Collection and Use</strong><br/>
                        For a better experience while using our Service, we may require you to provide us with certain personally identifiable information. 
                        The information that we request will be retained by us and used as described in this privacy policy. 
                        Our native applications uses third-party services that may collect information to identify you. Below are the links to the privacy policy of third-party service providers used by the app<br/>
                        ✤ <a className="text-sky-500" href="https://policies.google.com/privacy?hl=en-US" target="_blank">Google Play Services</a><br/>
                        ✤ <a className="text-sky-500" href="https://www.microsoft.com/en-us/privacy/privacystatement?msockid=1f0ffdb88897685c1717e93c892e6965" target="_blank">Microsoft store Services</a><br/><br/>
                        ✤ <strong>Log Data</strong><br/>
                        We inform you that whenever you use our Service, in a case of an error in the app, we collect data and information (through third-party products) on your phone called Log Data. 
                        This Log Data may include information such as your device's Internet Protocol address, device name, operating system version, 
                        the app's configuration when utilizing our Service, the time and date of your use of the Service, and other statistics.<br/><br/>
                        ✤ <strong>Cookies</strong><br/>
                        Cookies are files with a small amount of data that are commonly used as unique anonymous identifiers. 
                        These are sent to your browser from the websites that you visit and are stored on your device's internal memory. 
                        This Service does not use these "cookies" explicitly. However, the app may use third-party code and libraries that use "cookies" to collect information and improve their services. 
                        You can either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.<br/><br/>
                        ✤ <strong>Service Providers</strong><br/>
                        We may employ third-party companies and individuals for the following reasons: To facilitate our Service; To provide the Service on our behalf; 
                        To perform Service-related services; or To assist us in analyzing how our Service is used. 
                        We want to inform users of this Service that these third parties have access to your Personal Information. 
                        The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose. <br/><br/>
                        ✤ <strong>Security</strong><br/>
                        We value your trust in providing us with your Personal Information. 
                        Thus we are striving to use commercially acceptable means of protecting it. 
                        But remember that no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. 
                        Links to Other Sites This Service may contain links to other sites. You will be directed to that site if you click on a third-party link. 
                        Note that we do not operate these external sites. Therefore, we strongly advise you to review the Privacy Policy of these websites. 
                        We have no control over and assume no responsibility for any third-party sites or services' content, privacy policies, or practices.<br/><br/>
                        ✤ <strong>Children's Privacy</strong><br/>
                        These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. 
                        If we discover a child under 13 has provided us with personal information, we immediately delete this from our servers. 
                        If you are a parent or guardian and know that your child has provided us with personal information, don't hesitate to contact us so we can take the necessary actions.<br/><br/>
                        ✤ <strong>Changes to This Privacy Policy</strong><br/>
                        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. 
                        We will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 2021-01-01
                    </h5>
                  </details>
                </aside>





            </div>


        </div> {/* row */}
    </div> {/* container */}
</section>    
        </>
    
      );
}