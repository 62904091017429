
import banner from "../../assets/images/banner-bg.svg";
import hero from "../../assets/images/header-hero.png";




export default function Cta() {
    return (
      <div id="home" className="header-hero" style={{backgroundImage: banner}}>
      <div className="container">
          <div className="justify-center row">
              <div className="w-full lg:w-2/3">
                  <div className="pt-6 mb-12 text-center lg:pt-12 header-hero-content">
                      <h1 className="text-3xl text leading-tight text-sky-600 dark:text-sky-400 header-sub-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s"><strong>Entrust us with the care of building your digital solutions.</strong> </h1><br/>
                      <p className="mb-8 text-gray-600 dark:text-gray-400 text wow fadeInUp text-lg" data-wow-duration="1.3s" data-wow-delay="0.8s">
                      Software application design, development, testing, and maintenance cannot be entrusted to anyone.
                          With years of experience, Arfusoft positions itself today as one of the major players in the development of web and native applications.
                          We have the human and the technical resources to turn your ideas into practical software solutions.
                      </p>
                      <a className="main-btn bg-gradient-to-r from-indigo-600 to-sky-400 page-scroll lg:w-1/3 md:w-1/2 w-full" data-scroll-nav="0" href="/contact" rel="nofollow">Contact Us</a>


                  </div> {/* header hero content */}
              </div>
          </div> {/* row */}
          <div className="justify-center row">
              <div className="w-full lg:w-2/3">
                  <div className="text-center header-hero-image wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1.4s">
                      <img src={hero} alt="hero"/>
                  </div> {/* header hero image */}
              </div>
          </div> {/* row */}
      </div> {/* container */}
      <div id="particles-1" className="particles"></div>
  </div>
    );
  }