
import desktop3 from "../assets/images/desktop3-removebg-preview.png";
import mobile from "../assets/images/mobile-removebg-preview.png";
import web from "../assets/images/web-removebg-preview.png";
import {Helmet} from "react-helmet";



export default function Applications (){
    return (
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Arfusoft Applications</title>
        <meta name="description" content="Software Solutions by Arfusoft." />              
        <meta name="keywords" content="arfusoft,software,develop software,mobile applications,desktop applications,backend,frontend,react,kotlin,go"/>
        <meta property="og:title" content="Arfusoft Applications" />        
        <meta property="og:description" content="Software Solutions by Arfusoft." />        
        <meta property="og:site_name" content="Arfusoft Applications" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>



<section id="app" className="blog-area pt-120">
    <div className="container">
        <div className="row">
            <div className="w-full lg:w-1/2">
                <div className="pb-8 section-title">
                    <div className="line"></div>
                    <h3 className="title text-purple-500">Applications <span></span> </h3>
                </div> {/* section title */}
            </div>
        </div> {/* row */}
        <div className="justify-center row">
            <div className="w-full md:w-2/3 lg:w-1/3">
                <div className="mx-4 mt-10 single-blog wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                    <div className="mb-5 overflow-hidden blog-image rounded-xl">
                        <img className="w-full" src={desktop3} alt="blog"/>
                    </div>

                    <p className="text text-gray-600 text-lg dark:text-gray-400">
                        <strong>Desktop</strong> applications are high-speed. A desktop application is likely to run more smoothly on your hardware. 
                        They're easier to access (once installed). 
                        Yes, you have to install desktop apps. But once installed, they're right there on your desktop. 
                        The app icon is ever-present in your taskbar, always visible and accessible at a click. 
                        If you've enabled it, it might launch on login. Desktop apps are easier to access than non-native alternatives. 
                        At Arfusoft, we can build a native desktop application that integrates well with your computer hardware. 
                        We have developed several applications that are present in different application stores. <br/><br/><br/><br/> 




                        <a className="main-btn bg-gradient-to-r from-indigo-600 to-sky-400 page-scroll w-full" data-scroll-nav="0" href="https://apps.microsoft.com/store/search?publisher=Arfusoft" target="_blank" rel="noopener noreferrer">Our Microsoft store developer page</a>

                    </p>

                </div> {/* single blog */}
            </div> 
            <div className="w-full md:w-2/3 lg:w-1/3">
                <div className="mx-4 mt-10 single-blog wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                    <div className="mb-5 overflow-hidden blog-image rounded-xl">
                        <img className="w-full" src={mobile} alt="blog"/>
                    </div>
                    <p className="text text-gray-600 text-lg dark:text-gray-400">
                        <strong>Mobile</strong> applications have many advantages. <br/>
                        Mobile applications are way faster than mobile websites. 
                        Businesses are all about giving the service as fast as possible today. A slight lag can cause massive losses, leading to customer discontent. 
                        Mobile apps provide customer-appropriate content and personalized experience.
                        Analyzing user behaviors, geography, language, and interest apps can tailor a delightful customer experience.
                        One of the exciting advantages of mobile apps is that they can be accessed offline. Serving the service offline develops a seamless customer experience.
                        Mobile apps can leverage the features of native devices seamlessly.
                        It opens an excellent opportunity to engage with the users productively. 
                         <br/><br/>




                        <a className="main-btn bg-gradient-to-r from-indigo-600 to-sky-400 page-scroll w-full" data-scroll-nav="0" href="https://play.google.com/store/apps/dev?id=8770747534619679679" target="_blank" rel="noopener noreferrer">Our Android developer page</a>

                    </p>

                </div> {/* single blog */}
            </div> 
            <div className="w-full md:w-2/3 lg:w-1/3">
                <div className="mx-4 mt-10 single-blog wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                    <div className="mb-5 overflow-hidden blog-image rounded-xl">
                        <img className="w-full" src={web} alt="blog"/>
                    </div>
                    <p className="text text-gray-600 text-lg dark:text-gray-400">
                        Choose <strong>web </strong> applications when you want better accessibility. 
                        The main advantage of web applications is their accessibility.<br/>
                        Your employees and your customers can access your website on most browsers. 
                        The web app will work uniformly on every operating system. 
                        Once the developer deploys the app onto the remote server, it is instantly accessible via its address or URL. 
                        Updates are limited to the host server. Users are not affected and sometimes won't even notice. 
                        It is, therefore, easy to update a website. For a company, that means less cost as well. <br/>
                        <strong>
                            At Arfusoft, we ported some of our native applications to the browser. They are accessible on the following websites: 
                        </strong><br/> <br/>
                         

                        <div className="py-1"><a className="main-btn  bg-gradient-to-r from-indigo-600 to-sky-400 page-scroll w-full" data-scroll-nav="0" href="https://facilemath.com" target="_blank" rel="noopener noreferrer">Facile Math Website</a></div>
                        <div className="py-1"><a className="main-btn  bg-gradient-to-r from-indigo-600 to-sky-400 page-scroll w-full" data-scroll-nav="0" href="https://alleramusic.com" target="_blank" rel="noopener noreferrer">All Era Music Website</a></div>
                        <div className="py-1"><a className="main-btn  bg-gradient-to-r from-indigo-600 to-sky-400 page-scroll w-full" data-scroll-nav="0" href="https://libpeople.com" target="_blank" rel="noopener noreferrer">Library of the People</a></div>
                        <div className="py-1"><a className="main-btn bg-gradient-to-r from-indigo-600 to-sky-400 page-scroll w-full" data-scroll-nav="0" href="https://dreamsgist.com" target="_blank" rel="noopener noreferrer">Dreams Gist</a></div>
                    </p>

                </div> {/* single blog */}
            </div> 
        </div> {/* row */}
    </div> {/* container */}
</section>
        </>
    )
}