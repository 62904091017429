import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";


function Domain({domain}) {
    return (
        <>
            {(domain.toLowerCase().includes(".org")) &&
                <>
                    <div className="flex justify-center items-center">
                        <a href={`https://${domain}`} target="_blank"
                           className="mt-3 text-purple-600 inline-flex font-bold items-center px-12">{domain}</a>
                    </div>
                    <hr/>
                </>
            }
            {(domain.toLowerCase().includes(".net")) &&
                <>
                    <div className="flex justify-center items-center">
                        <a href={`https://${domain}`} target="_blank"
                           className="mt-3 text-sky-600 inline-flex font-bold items-center px-12">{domain}</a>
                    </div>
                    <hr/>
                </>
            }
            {(domain.toLowerCase().includes(".com")) &&
                <>
                    <div className="flex justify-center items-center">
                        <a href={`https://${domain}`} target="_blank"
                           className="mt-3 text-orange-600 inline-flex font-bold items-center px-12">{domain}</a>
                    </div>
                    <hr/>
                </>
            }
            {!(domain.toLowerCase().includes(".com")) &&
                !(domain.toLowerCase().includes(".net")) &&
                !(domain.toLowerCase().includes(".org")) &&
                <>
                    <div className="flex justify-center items-center">
                        <a href={`https://${domain}`} target="_blank"
                           className="mt-3 text-indigo-500 inline-flex items-center px-12">{domain}</a>
                    </div>
                    <hr/>
                </>
            }
        </>
    );
}

function PortfolioAll() {
    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);


    useEffect(() => {
        fetch(`https://marketing-bmphr.ondigitalocean.app/domains/portfolio`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [])

    if (error) {
        return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
    } else if (items === null || items.length === 0) {
        return <div className="px-6 py-24 flex justify-center items-center">
            There has yet to be a domain in this portfolio.</div>;
    } else {


        return (
            <>

                <br/><br/>

                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Arfusoft domain names</title>
                    <link rel="canonical" href="https://arfusoft.com/portfolio"/>
                    <meta name="description" content="Brandable domain names by Arfusoft."/>
                    <meta name="keywords" content="arfusoft,domains,buy domain names"/>
                    <meta property="og:title" content="Arfusoft domain names"/>
                    <meta property="og:description" content="Brandable domain names by Arfusoft."/>
                    <meta property="og:site_name" content="Arfusoft domain names"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="article"/>
                </Helmet>


                <div className="w-full justify-center items-center">

                    <div className=" mt-6 container mx-auto px-2">
                        <div className="justify-center  row  flex justify-center items-center ">
                            <div className="w-full lg:w-2/3 ">
                                <div className="pb-8 text-center section-title">
                                    <div className="m-auto line "></div>
                                    <h3 className="title text-purple-500 font-bold text-xl"> Domain Names</h3>
                                </div>
                                {/* section title */}
                            </div>
                        </div>
                        {/* row */}
                        <div className="justify-center row">
                            <h2 className="text-black-500 dark:text-white py-4">
                                Arfusoft has diversified its activities and is now investing in domain names.
                                Here is a non-exhaustive list of some awesome names in the Arfusoft domain portfolio.
                                If you are interested in one of the domain names, click on it, and you should be able to
                                make an offer.
                                Once you make an offer, no matter the amount, we get back to you as soon as possible to
                                let you know if we accept it.
                            </h2>
                        </div>
                    </div>
                    <div className="px-6 py-6 flex justify-center items-center flex-wrap space-x-2">
                        <a href="/portfolio" className="btn btn-sm btn-error">All</a>
                        <a href="/portfolio/com" className="btn btn-sm">Com</a>
                        <a href="/portfolio/net" className="btn btn-sm">Net</a>
                        <a href="/portfolio/org" className="btn btn-sm">Org</a>
                        <a href="/portfolio/others" className="btn btn-sm">Others</a>
                    </div>
                    <div className="px-6 py-6 flex justify-center items-center">
                        <input type="text" placeholder="Search by domain name"
                               className="input-ghost-secondary input w-full max-w-md" value={searchItem}
                               onChange={event => setSearchItem(event.target.value)}></input>
                    </div>

                    <div className="container px-5 py-5 mx-auto">

                        {items.filter((domain) => //items here
                        {
                            if (searchItem === "") {
                                return domain;
                            } else if
                            (domain.Domain.toLowerCase().includes(searchItem.toLowerCase())) {
                                return domain;
                            } else return null

                        }).map(domain => <Domain key={domain.Domain} domain={domain.Domain}/>)}
                    </div>
                </div>


            </>

        );
    }
}

function PortfolioCom() {
    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);


    useEffect(() => {
        fetch(`https://marketing-bmphr.ondigitalocean.app/domains/portfolio`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [])

    if (error) {
        return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
    } else if (items === null || items.length === 0) {
        return <div className="px-6 py-24 flex justify-center items-center">
            There has yet to be a domain in this portfolio.</div>;
    } else {


        return (
            <>
                <br/><br/>

                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Arfusoft COM domain names</title>
                    <link rel="canonical" href="https://arfusoft.com/portfolio/com"/>
                    <meta name="description" content="Brandable .COM domain names by Arfusoft."/>
                    <meta name="keywords" content="arfusoft,domains,.com domains,buy domain names"/>
                    <meta property="og:title" content="Arfusoft domain names"/>
                    <meta property="og:description" content="Brandable .COM domain names by Arfusoft."/>
                    <meta property="og:site_name" content="Arfusoft domain names"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="article"/>
                </Helmet>

                <div className="w-full justify-center items-center">

                    <div className=" mt-6 container mx-auto px-2">
                        <div className="justify-center  row  flex justify-center items-center ">
                            <div className="w-full lg:w-2/3 ">
                                <div className="pb-8 text-center section-title">
                                    <div className="m-auto line "></div>
                                    <h3 className="title text-purple-500 font-bold text-xl"> Domain Names</h3>
                                </div>
                                {/* section title */}
                            </div>
                        </div>
                        {/* row */}
                        <div className="justify-center row">
                            <h2 className="text-black-500 dark:text-white py-4">
                                Arfusoft has diversified its activities and is now investing in domain names.
                                Here is a non-exhaustive list of some awesome names in the Arfusoft domain portfolio.
                                If you are interested in one of the domain names, click on it, and you should be able to
                                make an offer.
                                Once you make an offer, no matter the amount, we get back to you as soon as possible to
                                let you know if we accept it.
                            </h2>
                        </div>
                    </div>


                    <div className="px-6 py-6 flex justify-center items-center flex-wrap space-x-2">
                        <a href="/portfolio" className="btn btn-sm">All</a>
                        <a href="/portfolio/com" className="btn btn-sm btn-error">Com</a>
                        <a href="/portfolio/net" className="btn btn-sm">Net</a>
                        <a href="/portfolio/org" className="btn btn-sm">Org</a>
                        <a href="/portfolio/others" className="btn btn-sm">Others</a>
                    </div>
                    <div className="px-6 py-6 flex justify-center items-center">
                        <input type="text" placeholder="Search by domain name"
                               className="input-ghost-secondary input w-full max-w-md" value={searchItem}
                               onChange={event => setSearchItem(event.target.value)}></input>
                    </div>

                    <div className="container px-5 py-5 mx-auto">

                        {items
                            .filter(domain => domain.Domain.toLowerCase().includes(".com"))
                            .filter((domain) => //items here
                            {
                                if (searchItem === "") {
                                    return domain;
                                } else if
                                (domain.Domain.toLowerCase().includes(searchItem.toLowerCase())) {
                                    return domain;
                                } else return null

                            })

                            .map(domain =>
                                <Domain key={domain.Domain} domain={domain.Domain}/>
                            )}
                    </div>
                </div>


            </>

        );
    }
}

function PortfolioNet() {
    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);


    useEffect(() => {
        fetch(`https://marketing-bmphr.ondigitalocean.app/domains/portfolio`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [])

    if (error) {
        return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
    } else if (items === null || items.length === 0) {
        return <div className="px-6 py-24 flex justify-center items-center">
            There has yet to be a domain in this portfolio.</div>;
    } else {


        return (
            <>
                <br/><br/>

                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Arfusoft NET domain names</title>
                    <link rel="canonical" href="https://arfusoft.com/portfolio/net"/>
                    <meta name="description" content="Brandable .NET domain names by Arfusoft."/>
                    <meta name="keywords" content="arfusoft,domains,.net domains,buy domain names"/>
                    <meta property="og:title" content="Arfusoft domain names"/>
                    <meta property="og:description" content="Brandable .NET domain names by Arfusoft."/>
                    <meta property="og:site_name" content="Arfusoft domain names"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="article"/>
                </Helmet>

                <div className="w-full justify-center items-center">

                    <div className=" mt-6 container mx-auto px-2">
                        <div className="justify-center  row  flex justify-center items-center ">
                            <div className="w-full lg:w-2/3 ">
                                <div className="pb-8 text-center section-title">
                                    <div className="m-auto line "></div>
                                    <h3 className="title text-purple-500 font-bold text-xl"> Domain Names</h3>
                                </div>
                                {/* section title */}
                            </div>
                        </div>
                        {/* row */}
                        <div className="justify-center row">
                            <h2 className="text-black-500 dark:text-white py-4">
                                Arfusoft has diversified its activities and is now investing in domain names.
                                Here is a non-exhaustive list of some awesome names in the Arfusoft domain portfolio.
                                If you are interested in one of the domain names, click on it, and you should be able to
                                make an offer.
                                Once you make an offer, no matter the amount, we get back to you as soon as possible to
                                let you know if we accept it.
                            </h2>
                        </div>
                    </div>
                    <div className="px-6 py-6 flex justify-center items-center flex-wrap space-x-2">
                        <a href="/portfolio" className="btn btn-sm">All</a>
                        <a href="/portfolio/com" className="btn btn-sm">Com</a>
                        <a href="/portfolio/net" className="btn btn-sm btn-error">Net</a>
                        <a href="/portfolio/org" className="btn btn-sm">Org</a>
                        <a href="/portfolio/others" className="btn btn-sm">Others</a>
                    </div>
                    <div className="px-6 py-6 flex justify-center items-center">
                        <input type="text" placeholder="Search by domain name"
                               className="input-ghost-secondary input w-full max-w-md" value={searchItem}
                               onChange={event => setSearchItem(event.target.value)}></input>
                    </div>

                    <div className="container px-5 py-5 mx-auto">

                        {items
                            .filter(domain => domain.Domain.toLowerCase().includes(".net"))
                            .filter((domain) => //items here
                            {
                                if (searchItem === "") {
                                    return domain;
                                } else if
                                (domain.Domain.toLowerCase().includes(searchItem.toLowerCase())) {
                                    return domain;
                                } else return null

                            })

                            .map(domain =>
                                <Domain key={domain.Domain} domain={domain.Domain}/>
                            )}
                    </div>
                </div>


            </>

        );
    }
}

function PortfolioOrg() {
    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);


    useEffect(() => {
        fetch(`https://marketing-bmphr.ondigitalocean.app/domains/portfolio`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [])

    if (error) {
        return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
    } else if (items === null || items.length === 0) {
        return <div className="px-6 py-24 flex justify-center items-center">
            There has yet to be a domain in this portfolio.</div>;
    } else {


        return (
            <>
                <br/><br/>

                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Arfusoft ORG domain names</title>
                    <link rel="canonical" href="https://arfusoft.com/portfolio/org"/>
                    <meta name="description" content="Brandable .ORG domain names by Arfusoft."/>
                    <meta name="keywords" content="arfusoft,domains,.org domains,buy domain names"/>
                    <meta property="og:title" content="Arfusoft domain names"/>
                    <meta property="og:description" content="Brandable .ORG domain names by Arfusoft."/>
                    <meta property="og:site_name" content="Arfusoft domain names"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="article"/>
                </Helmet>

                <div className="w-full justify-center items-center">

                    <div className=" mt-6 container mx-auto px-2">
                        <div className="justify-center  row  flex justify-center items-center ">
                            <div className="w-full lg:w-2/3 ">
                                <div className="pb-8 text-center section-title">
                                    <div className="m-auto line "></div>
                                    <h3 className="title text-purple-500 font-bold text-xl"> Domain Names</h3>
                                </div>
                                {/* section title */}
                            </div>
                        </div>
                        {/* row */}
                        <div className="justify-center row">
                            <h2 className="text-black-500 dark:text-white py-4">
                                Arfusoft has diversified its activities and is now investing in domain names.
                                Here is a non-exhaustive list of some awesome names in the Arfusoft domain portfolio.
                                If you are interested in one of the domain names, click on it, and you should be able to
                                make an offer.
                                Once you make an offer, no matter the amount, we get back to you as soon as possible to
                                let you know if we accept it.
                            </h2>
                        </div>
                    </div>
                    <div className="px-6 py-6 flex justify-center items-center flex-wrap space-x-2">
                        <a href="/portfolio" className="btn btn-sm">All</a>
                        <a href="/portfolio/com" className="btn btn-sm">Com</a>
                        <a href="/portfolio/net" className="btn btn-sm">Net</a>
                        <a href="/portfolio/org" className="btn btn-sm btn-error">Org</a>
                        <a href="/portfolio/others" className="btn btn-sm">Others</a>
                    </div>
                    <div className="px-6 py-6 flex justify-center items-center">
                        <input type="text" placeholder="Search by domain name"
                               className="input-ghost-secondary input w-full max-w-md" value={searchItem}
                               onChange={event => setSearchItem(event.target.value)}></input>
                    </div>

                    <div className="container px-5 py-5 mx-auto">

                        {items
                            .filter(domain => domain.Domain.toLowerCase().includes(".org"))
                            .filter((domain) => //items here
                            {
                                if (searchItem === "") {
                                    return domain;
                                } else if
                                (domain.Domain.toLowerCase().includes(searchItem.toLowerCase())) {
                                    return domain;
                                } else return null

                            })

                            .map(domain =>
                                <Domain key={domain.Domain} domain={domain.Domain}/>
                            )}
                    </div>
                </div>


            </>

        );
    }
}

function PortfolioOthers() {
    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);


    useEffect(() => {
        fetch(`https://marketing-bmphr.ondigitalocean.app/domains/portfolio`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [])

    if (error) {
        return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
    } else if (items === null || items.length === 0) {
        return <div className="px-6 py-24 flex justify-center items-center">
            There has yet to be a domain in this portfolio.</div>;
    } else {


        return (
            <>
                <br/><br/>

                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Arfusoft CO, AI, IO and others domain names</title>
                    <link rel="canonical" href="https://arfusoft.com/portfolio/others"/>
                    <meta name="description" content="Brandable CO, AI, IO and others domain names by Arfusoft."/>
                    <meta name="keywords" content="arfusoft,domains,.org domains,buy domain names"/>
                    <meta property="og:title" content="Arfusoft CO, AI, IO and others domain names"/>
                    <meta property="og:description" content="Brandable CO, AI, IO and others domain names by Arfusoft."/>
                    <meta property="og:site_name" content="Arfusoft CO, AI, IO and others domain names"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="article"/>
                </Helmet>

                <div className="w-full justify-center items-center">

                    <div className=" mt-6 container mx-auto px-2">
                        <div className="justify-center  row  flex justify-center items-center ">
                            <div className="w-full lg:w-2/3 ">
                                <div className="pb-8 text-center section-title">
                                    <div className="m-auto line "></div>
                                    <h3 className="title text-purple-500 font-bold text-xl"> Domain Names</h3>
                                </div>
                                {/* section title */}
                            </div>
                        </div>
                        {/* row */}
                        <div className="justify-center row">
                            <h2 className="text-black-500 dark:text-white py-4">
                                Arfusoft has diversified its activities and is now investing in domain names.
                                Here is a non-exhaustive list of some awesome names in the Arfusoft domain portfolio.
                                If you are interested in one of the domain names, click on it, and you should be able to
                                make an offer.
                                Once you make an offer, no matter the amount, we get back to you as soon as possible to
                                let you know if we accept it.
                            </h2>
                        </div>
                    </div>

                    <div className="px-6 py-6 flex justify-center items-center flex-wrap space-x-2">
                        <a href="/portfolio" className="btn btn-sm">All</a>
                        <a href="/portfolio/com" className="btn btn-sm">Com</a>
                        <a href="/portfolio/net" className="btn btn-sm">Net</a>
                        <a href="/portfolio/org" className="btn btn-sm">Org</a>
                        <a href="/portfolio/others" className="btn btn-sm btn-error">Others</a>
                    </div>
                    <div className="px-6 py-6 flex justify-center items-center">
                        <input type="text" placeholder="Search by domain name"
                               className="input-ghost-secondary input w-full max-w-md" value={searchItem}
                               onChange={event => setSearchItem(event.target.value)}></input>
                    </div>

                    <div className="container px-5 py-5 mx-auto">

                        {items
                            .filter(domain => !domain.Domain.toLowerCase().includes(".org") &&
                                !domain.Domain.toLowerCase().includes(".net") &&
                                !domain.Domain.toLowerCase().includes(".com"))
                            .filter((domain) => //items here
                            {
                                if (searchItem === "") {
                                    return domain;
                                } else if
                                (domain.Domain.toLowerCase().includes(searchItem.toLowerCase())) {
                                    return domain;
                                } else return null

                            })

                            .map(domain =>
                                <Domain key={domain.Domain} domain={domain.Domain}/>
                            )}
                    </div>
                </div>


            </>

        );
    }
}

export {PortfolioAll, PortfolioCom, PortfolioNet, PortfolioOrg, PortfolioOthers};