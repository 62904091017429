import { Outlet } from "react-router-dom";
import TopAppBar from "./TopAppBar";
import Footer from "./landing/Footer";

const Layout = () => {
    return(
        <div>
            <TopAppBar></TopAppBar>
            <Outlet />
            <Footer/>
        </div>
    )
}

export default Layout